const BuildTradeDetails = (trade, currentUsername) => {
  const currentUserInitiated = currentUsername === trade.initiatorUsername

  const initiatorDisplayName = currentUserInitiated ? "You" : trade.initiatorUsername

  const requestedDisplayName = currentUserInitiated ? trade.requestedUsername : "You"

  const timestampText = new Date(trade.updatedAt).toDateString()

  const tradeInfo = {
    currentUserInitiated,
    initiatorDisplayName,
    requestedDisplayName,
    timestampText,
    tradeId: trade.tradeId,
    internalId: trade.tradeInternalId,
    updatedAt: trade.updatedAt,
  }

  const initiatorPuzzle = {
    title: trade.initiatorPuzzleTitle,
    brand: trade.initiatorPuzzleBrand,
    numPieces: trade.initiatorPuzzleNumPieces,
    numPiecesMissing: trade.initiatorPuzzleNumPiecesMissing,
    tags: trade.initiatorPuzzleTags,
    imageUrl: trade.initiatorPuzzleImageUrl,
    ownerUsername: trade.initiatorUsername,
    city: trade.initiatorCity,
    street: trade.initiatorStreet,
    zip: trade.initiatorZip,
    shippedStatus: trade.initiatorPuzzleStatus,
    shippedVia: trade.initiatorPuzzleShippedVia,
    shippedTrackingNo: trade.initiatorPuzzleShippedTrackingNo,
  }

  const requestedPuzzle = {
    title: trade.requestedPuzzleTitle,
    tags: trade.requestedPuzzleTags,
    brand: trade.requestedPuzzleBrand,
    numPieces: trade.requestedPuzzleNumPieces,
    numPiecesMissing: trade.requestedPuzzleNumPiecesMissing,
    imageUrl: trade.requestedPuzzleImageUrl,
    ownerUsername: trade.requestedUsername,
    city: trade.requestedCity,
    street: trade.requestedStreet,
    zip: trade.requestedZip,
    shippedStatus: trade.requestedPuzzleStatus,
    shippedVia: trade.requestedPuzzleShippedVia,
    shippedTrackingNo: trade.requestedPuzzleShippedTrackingNo,
  }

  initiatorPuzzle.needToShip = !initiatorPuzzle.shippedStatus || initiatorPuzzle.shippedStatus === "notshipped"
  requestedPuzzle.needToShip = !requestedPuzzle.shippedStatus || requestedPuzzle.shippedStatus === "notshipped"

  initiatorPuzzle.isShipped = initiatorPuzzle.shippedStatus === "shipped"
  requestedPuzzle.isShipped = requestedPuzzle.shippedStatus === "shipped"

  initiatorPuzzle.isReceived = initiatorPuzzle.shippedStatus === "received"
  requestedPuzzle.isReceived = requestedPuzzle.shippedStatus === "received"

  return {
    tradeInfo,
    initiatorPuzzle,
    requestedPuzzle,
  }
}

export default BuildTradeDetails

import React, { useEffect, useState, useContext } from "react"
import { Helmet } from "react-helmet-async"

import ActiveTradesList from "../components/trades/ActiveTradesList"
import IncomingTradesList from "../components/trades/IncomingTradesList"
import ProposedTradesList from "../components/trades/ProposedTradesList"
import jiggswapApi from "../api/jiggswap"
import { AuthContext } from "../context/AuthContext"
import RequireAuth from "../components/auth/RequireAuth"

const TradesIndex = () => {
  const [activeTrades, setActiveTrades] = useState([])
  const [proposedTrades, setProposedTrades] = useState([])
  const [incomingTrades, setIncomingTrades] = useState([])
  const {
    state: { username },
  } = useContext(AuthContext)

  const fetchTrades = async () => {
    const response = await jiggswapApi.get("/trades")
    const allTrades = response.data.trades

    setActiveTrades(allTrades.filter((t) => t.status === "active"))

    setProposedTrades(allTrades.filter((t) => t.status === "proposed" && t.initiatorUsername === username))

    setIncomingTrades(allTrades.filter((t) => t.status === "proposed" && t.requestedUsername === username))
  }

  useEffect(() => {
    if (username) {
      fetchTrades()
    }

    //eslint-disable-next-line
  }, [username])

  return (
    <>
      <Helmet title="My Trades - JiggSwap" />
      <RequireAuth />

      <div className="is-size-2 has-text-centered">My Trades</div>

      <div className="section">
        <ActiveTradesList trades={activeTrades} refreshTrades={fetchTrades} currentUsername={username} />
      </div>
      <div className="section">
        <IncomingTradesList trades={incomingTrades} refreshTrades={fetchTrades} currentUsername={username} />
      </div>
      <div className="section">
        <ProposedTradesList trades={proposedTrades} refreshTrades={fetchTrades} currentUsername={username} />
      </div>
    </>
  )
}

export default TradesIndex

import React from "react"
import BuildTradeDetails from "../../api/helpers/trade"
import TradeListItemDetails from "./TradeListItemDetails"
import jiggswapApi from "api/jiggswap"

const ProposedTradeListItem = ({ trade, onRemove, currentUsername }) => {
  const { tradeInfo, initiatorPuzzle, requestedPuzzle } = BuildTradeDetails(trade, currentUsername)

  const leftPuzzle = tradeInfo.currentUserInitiated ? initiatorPuzzle : requestedPuzzle
  const rightPuzzle = tradeInfo.currentUserInitiated ? requestedPuzzle : initiatorPuzzle

  const cancelTrade = async () => {
    await jiggswapApi.post("/trades/cancel", { tradeId: trade.tradeId })
    onRemove()
  }

  return (
    <div className="box trade-box">
      <div className="has-text-centered">
        {tradeInfo.initiatorDisplayName} proposed this trade to {tradeInfo.requestedDisplayName} on {tradeInfo.timestampText}.
      </div>
      <hr />

      <TradeListItemDetails leftPuzzle={leftPuzzle} rightPuzzle={rightPuzzle} />

      <hr />
      <div className="has-text-centered">
        This trade is NOT YET FINALIZED! We are waiting to hear back from {trade.requestedUsername}. <br />
        Wait to see if they accept before you mail anything.
      </div>
      <div className="trade-actions">
        <div className="button is-danger" onClick={cancelTrade} onKeyPress={cancelTrade} role="button" tabIndex={0}>
          Cancel Trade
        </div>
      </div>
    </div>
  )
}

const ProposedTradesList = ({ trades, refreshTrades, currentUsername }) => {
  return (
    <div className="message is-primary">
      <div className="message-header">Proposed Trades ( {trades.length} )</div>
      <div className="has-text-centered">These are trades proposed by YOU, sent to OTHER USERS.</div>
      <div className="has-text-centered">You don't need to do anything with these trades, but you may cancel them if you wish.</div>
      <div className="message-body">
        {trades.map((t) => (
          <ProposedTradeListItem key={t.tradeId} trade={t} onRemove={refreshTrades} currentUsername={currentUsername} />
        ))}
      </div>
    </div>
  )
}

export default ProposedTradesList

import React from "react"

const TradePuzzleItem = ({ puzzle }) => {
  return (
    <>
      <div className="is-size-4">{puzzle.ownerUsername}'s puzzle:</div>
      <div className="is-size-6">
        <span>Shipping from: </span>
        <span>{puzzle.city}</span>
        {!puzzle.city && <span className="has-text-weight-light">Unknown</span>}
      </div>
      <hr />
      <div>
        <figure className="image is-4by3">
          <img src={puzzle.imageUrl} alt={puzzle.title} />
        </figure>
      </div>
      <hr />
      <div>
        <div>
          <span className="has-text-weight-medium m-2">Title:</span>
          <span>{puzzle.title}</span>
        </div>
        <div>
          <span className="has-text-weight-medium m-2">Brand:</span>
          <span>{puzzle.brand}</span>
        </div>
        <div>
          <span className="has-text-weight-medium m-2"># Pieces:</span>
          <span>
            {puzzle.numPieces} (<span className="has-text-weight-medium">{puzzle.numPiecesMissing}</span> missing)
          </span>
        </div>
      </div>
    </>
  )
}

const TradeListItemDetails = ({ leftPuzzle, rightPuzzle }) => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 3 }} className="has-text-centered">
          <TradePuzzleItem puzzle={leftPuzzle} />
        </div>
        <div style={{ flex: 1, flexShrink: 0, margin: "auto 0", textAlign: "center" }}>
          <div className="is-size-1 is-size-5-mobile">⇄</div>
        </div>
        <div style={{ flex: 3 }} className="has-text-centered">
          <TradePuzzleItem puzzle={rightPuzzle} />
        </div>
      </div>
    </>
  )
}

export default TradeListItemDetails

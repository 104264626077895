import React, { useEffect } from "react"
import { useForm } from "react-hook-form"

import states from "../../data/states"

import jiggswapApi from "../../api/jiggswap"
import { MapApiErrors } from "../../helpers/ErrorParser"
import JiggswapInput from "../forms/JiggswapInput"
import JiggswapSelectList from "../forms/JiggswapSelectList"

const AddressModal = ({ active, setActive }) => {
  const AddressForm = () => {
    const { register, handleSubmit, errors, setError, setValue } = useForm()

    useEffect(() => {
      const fetchData = async () => {
        var response = await jiggswapApi.get(`/userprofile`)

        Object.entries(response.data).forEach(([key, value]) => setValue(key, value))
      }

      fetchData()
    }, [setValue])

    const submitForm = async (values) => {
      try {
        await jiggswapApi.put(`/userprofile`, values)

        setActive(false)
      } catch (err) {
        var apiErrors = MapApiErrors(err.response.data.errors)

        setError(apiErrors)
      }
    }

    return (
      <>
        <form onSubmit={handleSubmit(submitForm)}>
          <JiggswapInput name="firstName" label="First Name" type="text" register={register} errors={errors} />

          <JiggswapInput name="lastName" label="Last Name (or Initial)" type="text" register={register} errors={errors} />

          <JiggswapInput name="streetAddress" label="Street Address" type="text" register={register} errors={errors} />

          <JiggswapInput name="city" type="text" register={register} errors={errors} />

          <JiggswapSelectList name="state" options={states} register={register} errors={errors} />

          <JiggswapInput name="zip" label="Zip Code" type="text" register={register} errors={errors} />

          <div className="has-text-centered">
            <button type="submit" className="button is-primary">
              Save Address
            </button>
          </div>
        </form>
      </>
    )
  }

  return (
    <div className={`modal ${active ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">JiggSwap needs your address information</p>
          <button className="delete" aria-label="close" onClick={() => setActive(null)}></button>
        </header>
        <section className="modal-card-body">
          <p className="has-text-centered">Before you can start trading puzzles.</p>

          <AddressForm />
        </section>
        <footer className="modal-card-foot has-text-centered">
          <button className="button" onClick={() => setActive(null)}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  )
}

export default AddressModal

import React, { useState } from "react"
import { useForm } from "react-hook-form"

import TradeListItemDetails from "./TradeListItemDetails"
import BuildTradeDetails from "../../api/helpers/trade"
import jiggswapApi from "../../api/jiggswap"
import { MapApiErrors } from "../../helpers/ErrorParser"
import JiggswapInput from "../forms/JiggswapInput"

const ShippedForm = ({ tradeId, onUpdate }) => {
  const { register, handleSubmit, errors, setError } = useForm({})

  const shipPuzzle = async ({ carrier, trackingNumber }) => {
    try {
      await jiggswapApi.post("/trades/shipped", { tradeId, carrier, trackingNumber })

      onUpdate()
    } catch (err) {
      var apiErrors = MapApiErrors(err.response.data.errors)

      setError(apiErrors)
    }
  }

  return (
    <form onSubmit={handleSubmit(shipPuzzle)} autoComplete="off">
      <JiggswapInput
        placeholder="USPS, FedEx, UPS, etc."
        type="text"
        name="carrier"
        label="Shipping Carrier"
        register={register}
        errors={errors}
      />

      <JiggswapInput type="text" helptext="(if available)" name="trackingNumber" label="Tracking #" register={register} errors={errors} />

      <div>
        <button type="submit" className="button is-success">
          Confirm Shipping Information
        </button>
      </div>
    </form>
  )
}

const ActiveTradeListItem = ({ trade, onUpdate, currentUsername }) => {
  const { tradeInfo, initiatorPuzzle, requestedPuzzle } = BuildTradeDetails(trade, currentUsername)

  const myPuzzle = tradeInfo.currentUserInitiated ? initiatorPuzzle : requestedPuzzle
  const otherPuzzle = tradeInfo.currentUserInitiated ? requestedPuzzle : initiatorPuzzle

  const [showShippedForm, setShowShippedForm] = useState(false)

  const ShippedInfo = ({ carrier, trackingNo }) => {
    return (
      <>
        <div>
          <span className="has-text-weight-medium">Shipping Carrier: </span>
          <span> {carrier}</span>
        </div>
        <div>
          <span className="has-text-weight-medium">Tracking #: </span>
          <span> {trackingNo}</span>
          <span className="has-text-weight-light">{!trackingNo && <> (Not Specified)</>}</span>
        </div>
      </>
    )
  }

  const receivePuzzle = async () => {
    try {
      await jiggswapApi.post("/trades/received", { tradeId: trade.tradeId })

      onUpdate()
    } catch (err) {}
  }

  return (
    <div className="box trade-box">
      <div className="has-text-centered">
        {tradeInfo.requestedDisplayName} accepted this trade from {tradeInfo.initiatorDisplayName} on {tradeInfo.timestampText}.
      </div>
      <hr />

      <TradeListItemDetails leftPuzzle={myPuzzle} rightPuzzle={otherPuzzle} />

      <hr />
      <div className="has-text-centered">
        This trade is <b>active</b>. Both users should send their puzzle to the designated address.
      </div>
      <hr />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }}>
          {myPuzzle.needToShip && (
            <>
              <div className="has-text-centered">
                <b>Please ship your puzzle to:</b>
                <div>
                  <div>{otherPuzzle.street}</div>
                  <div>
                    {otherPuzzle.city} {otherPuzzle.zip}
                  </div>
                </div>
              </div>
              <div className="trade-actions">
                {!showShippedForm && (
                  <button className="button is-primary" onClick={() => setShowShippedForm(true)}>
                    I've Sent My Puzzle
                  </button>
                )}
                {showShippedForm && (
                  <>
                    <ShippedForm tradeId={trade.tradeId} onUpdate={onUpdate} />
                  </>
                )}
              </div>
            </>
          )}
          {myPuzzle.isShipped && (
            <>
              <div className="has-text-centered">
                You have shipped this puzzle. We are waiting for a received confirmation from {otherPuzzle.ownerUsername}.
                <ShippedInfo carrier={myPuzzle.shippedVia} trackingNo={myPuzzle.shippedTrackingNo} />
              </div>
            </>
          )}
          {myPuzzle.isReceived && (
            <>
              <div className="has-text-centered">{otherPuzzle.ownerUsername} has received this puzzle.</div>
            </>
          )}
        </div>
        <div style={{ flex: 1 }}>
          {otherPuzzle.needToShip && (
            <>
              <div className="has-text-centered">
                {otherPuzzle.ownerUsername} will ship their puzzle to:
                <div>
                  <div>{myPuzzle.street}</div>
                  <div>
                    {myPuzzle.city} {myPuzzle.zip}
                  </div>
                </div>
              </div>
            </>
          )}
          {otherPuzzle.isShipped && (
            <>
              <div className="has-text-centered">
                <div>
                  {otherPuzzle.ownerUsername} has shipped their puzzle.
                  <ShippedInfo carrier={otherPuzzle.shippedVia} trackingNo={otherPuzzle.shippedTrackingNo} />
                </div>
              </div>
              <div className="trade-actions">
                <button className="button is-primary" onClick={receivePuzzle}>
                  I've Received This Puzzle
                </button>
              </div>
            </>
          )}
          {otherPuzzle.isReceived && (
            <>
              <div className="has-text-centered">You have received this puzzle.</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const ActiveTradesList = ({ trades, refreshTrades, currentUsername }) => {
  return (
    <div className="message is-primary">
      <div className="message-header">Active Trades ( {trades.length} )</div>
      <div className="message-body">
        {trades.map((t) => (
          <ActiveTradeListItem key={t.tradeId} onUpdate={refreshTrades} trade={t} currentUsername={currentUsername} />
        ))}
      </div>
    </div>
  )
}

export default ActiveTradesList

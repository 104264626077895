import React from "react"
import { ErrorMessage } from "react-hook-form"

const JiggswapSelectList = ({ name, label, errors, register, options }) => {
  const hasErrors = errors && errors[name] !== undefined
  const labelText = label || name[0].toUpperCase() + name.substr(1)

  return (
    <div className="field">
      <label className="label" htmlFor={name}>
        {labelText}
      </label>
      <div className={"select" + (hasErrors ? " is-danger" : "")}>
        <select name={name} id={name} ref={register}>
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.display}
            </option>
          ))}
        </select>
      </div>
      {hasErrors && (
        <p className="help is-danger">
          <ErrorMessage errors={errors} name={name} />
        </p>
      )}
    </div>
  )
}

export default JiggswapSelectList

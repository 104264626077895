import React, { useState } from "react"
import BuildTradeDetails from "../../api/helpers/trade"
import TradeListItemDetails from "./TradeListItemDetails"
import jiggswapApi from "../../api/jiggswap"
import AddressModal from "../profiles/AddressModal"

const IncomingTradesListItem = ({ trade, onAccept, onDecline, currentUsername }) => {
  const { tradeInfo, initiatorPuzzle, requestedPuzzle } = BuildTradeDetails(trade, currentUsername)

  const leftPuzzle = tradeInfo.currentUserInitiated ? initiatorPuzzle : requestedPuzzle
  const rightPuzzle = tradeInfo.currentUserInitiated ? requestedPuzzle : initiatorPuzzle

  return (
    <div className="box trade-box">
      <div className="has-text-centered">
        {tradeInfo.initiatorDisplayName} proposed this trade to {tradeInfo.requestedDisplayName} on {tradeInfo.timestampText}.
      </div>
      <hr />

      <TradeListItemDetails leftPuzzle={leftPuzzle} rightPuzzle={rightPuzzle} />

      <hr />
      <div className="has-text-centered">
        This trade has been proposed to YOU. <b>Do you want to accept it?</b>
      </div>
      <div className="trade-actions">
        <div className="button is-success" onClick={onAccept} onKeyPress={onAccept} role="button" tabIndex={0}>
          Accept Trade
        </div>
        <div className="button is-danger" onClick={onDecline} onKeyPress={onDecline} role="button" tabIndex={0}>
          Decline Trade
        </div>
      </div>
    </div>
  )
}

const IncomingTradesList = ({ trades, refreshTrades, currentUsername }) => {
  const [needAddress, setNeedAddress] = useState(null)

  const acceptTrade = async (tradeId) => {
    try {
      await jiggswapApi.post("/trades/accept", { tradeId })

      refreshTrades()
    } catch (err) {
      if (err.response.data.errors["currentUser.Address"]) {
        setNeedAddress(true)
      }
    }
  }

  const declineTrade = async (tradeId) => {
    await jiggswapApi.post("/trades/decline", { tradeId })

    refreshTrades()
  }

  return (
    <>
      {needAddress && <AddressModal active={needAddress} setActive={setNeedAddress} />}
      <div className="message is-secondary">
        <div className="message-header">Incoming Trades ( {trades.length} )</div>
        <div className="has-text-centered">These are trades proposed by OTHER USERS, sent to YOU.</div>
        <div className="message-body">
          {trades.map((t) => (
            <IncomingTradesListItem
              key={t.tradeId}
              onAccept={() => acceptTrade(t.tradeId)}
              onDecline={() => declineTrade(t.tradeId)}
              trade={t}
              currentUsername={currentUsername}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default IncomingTradesList
